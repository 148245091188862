// dependencies
import React from "react";
import { useIntl } from "react-intl";
import { StyleSheet, css } from "aphrodite";

// components
import Text from "../Text2";

// constants
import { DAY_TO_WEEK_BASE } from "@cloudspire/legacy-shared/src/constants/time";

// libraries
import { emToPx } from "../../libraries";

type PropsType = {
  shouldAddOffset: boolean;
};

/**
 * TODO: temporairement hardcodé car il n'existe pas de solution pour
 *   récupérer le premier jour de la semaine en fonction de la locale courante.
 */
const localeFirstDayOfWeek = 1;

const styles = StyleSheet.create({
  datePickerWeekDayNames: {
    display: "flex",
    backgroundColor: "#f7f7f7",
  },
  datePickerWeekDayNames_offset: {
    paddingLeft: emToPx(1),
  },
  day: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    height: "2rem",
  },
});

function DatePickerWeekDayNames(props: PropsType) {
  const { shouldAddOffset = false } = props;

  const intl = useIntl();

  return (
    <div
      className={css(
        styles.datePickerWeekDayNames,
        shouldAddOffset && styles.datePickerWeekDayNames_offset
      )}
    >
      {Array(DAY_TO_WEEK_BASE)
        .fill(null)
        .map((_, index) => {
          const dayIndex = (index + localeFirstDayOfWeek) % DAY_TO_WEEK_BASE;
          const dayName = intl.formatMessage(
            {
              defaultMessage:
                "{day, select, 0 {di} 1 {lu} 2 {ma} 3 {me} 4 {je} 5 {ve} 6 {sa} other {{day}}}",
            },
            { day: dayIndex }
          );

          return (
            <div key={dayName} className={css(styles.day)}>
              <Text key={dayName}>{dayName}</Text>
            </div>
          );
        })}
    </div>
  );
}

export default DatePickerWeekDayNames;
