// dependencies
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { StyleSheet, css } from "aphrodite";

// components
import Text from "@cloudspire/legacy-resources/src/components/Text";
import Stack from "@cloudspire/legacy-resources/src/components/Stack";
import DatePickerWeek from "./DatePickerWeek";
import DatePickerWeekDayNames from "./DatePickerWeekDayNames";

// constants
import {
  MAX_WEEKS_PER_MONTH,
  DAY_TO_WEEK_BASE,
} from "@cloudspire/legacy-shared/src/constants/time";

// libraries
import EnhancedDate from "@cloudspire/legacy-resources/src/libraries/EnhancedDate";
import { capitalize } from "@cloudspire/legacy-shared/src/libraries";
import { emToPx } from "@cloudspire/legacy-resources/src/libraries";

type PropsType = {
  date: EnhancedDate;
  month: EnhancedDate;
  shouldAddOffset: boolean;
  shouldDisplayMaxWeekPerMonth?: boolean;
};

const styles = StyleSheet.create({
  datePickerWeek: {
    display: "flex",
  },
  datePickerMonthName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 14,
    backgroundColor: "#f7f7f7",
  },
  datePickerMonthWeekContainer: {
    display: "flex",
    flexDirection: "column",
  },
  datePickerMonthWeekContainer__offset: {
    marginLeft: emToPx(1),
  },
  datePickerMonthWeekNameContainer__offset: {
    paddingLeft: emToPx(1),
  },
});

/**
 * TODO: temporairement hardcodé car il n'existe pas de solution pour
 *   récupérer le premier jour de la semaine en fonction de la locale courante.
 */
const localeFirstDayOfWeek = 1;

const DatePickerMonth: React.FunctionComponent<PropsType> = (props) => {
  const {
    date,
    month,
    shouldAddOffset,
    shouldDisplayMaxWeekPerMonth = false,
  } = props;

  const intl = useIntl();

  return useMemo(() => {
    const tempDate = date
      .clone()
      .addDate(
        -(
          (date.getFirstWeekDayOfMonth() +
            (DAY_TO_WEEK_BASE - localeFirstDayOfWeek)) %
          DAY_TO_WEEK_BASE
        )
      );

    const to = date.clone().setDate(date.getNumberOfDaysInMonth());

    const $weeks = [];

    let i = 0;
    while (
      tempDate.isLessOrEqualTo(to) ||
      (shouldDisplayMaxWeekPerMonth && i < MAX_WEEKS_PER_MONTH)
    ) {
      $weeks.push(
        <div
          className={css(styles.datePickerWeek)}
          key={tempDate.format("m-W")}
        >
          <DatePickerWeek date={tempDate.clone()} month={month} />
        </div>
      );

      tempDate.setDate((tempDate.getDate() + DAY_TO_WEEK_BASE) as any);
      i++;
    }

    return (
      <div>
        <div className={css(styles.datePickerMonthName)}>
          <Text style={{ fontWeight: "700" }}>
            {`${capitalize(
              intl.formatMessage(
                {
                  defaultMessage:
                    "{month, select, 0 {janvier} 1 {février} 2 {mars} 3 {avril} 4 {mai} 5 {juin} 6 {juillet} 7 {août} 8 {septembre} 9 {octobre} 10 {novembre} 11 {décembre} other {{month}}}",
                },
                { month: date.getMonth() }
              )
            )}\u00a0${date.format("Y")}`}
          </Text>
        </div>
        <DatePickerWeekDayNames shouldAddOffset={shouldAddOffset} />
        <Stack marginTop={0.625} />
        <div
          className={css(
            styles.datePickerMonthWeekContainer,
            shouldAddOffset && styles.datePickerMonthWeekContainer__offset
          )}
        >
          {$weeks}
        </div>
      </div>
    );
  }, [date.format("y-m"), shouldDisplayMaxWeekPerMonth, shouldAddOffset]);
};

export default DatePickerMonth;
