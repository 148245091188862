// deps
import { defineMessage } from "react-intl";

// Nature

/** Séjour particulier */
export const BOOKING_NATURE_VALUE_PAR = "pa";
/** Séjour professionnel */
export const BOOKING_NATURE_VALUE_PRO = "pr";

export const BOOKING_NATURES = {
  [BOOKING_NATURE_VALUE_PAR]: {
    id: BOOKING_NATURE_VALUE_PAR,
    label: "Particulier",
    metrics: {
      visible: true,
      color: "#81499A",
    },
  },
  [BOOKING_NATURE_VALUE_PRO]: {
    id: BOOKING_NATURE_VALUE_PRO,
    label: "Professionnel",
    metrics: {
      visible: true,
      color: "#CF7C7B",
    },
  },
};

export const BOOKING_NATURE_INTL_MESSAGES = {
  byValue: {
    [BOOKING_NATURE_VALUE_PAR]: {
      label: defineMessage({
        defaultMessage: "particulier",
      }),
      contractLabel: defineMessage({
        defaultMessage: "contrat particulier",
      }),
    },
    [BOOKING_NATURE_VALUE_PRO]: {
      label: defineMessage({
        defaultMessage: "professionnel",
      }),
      contractLabel: defineMessage({
        defaultMessage: "contrat professionnel",
      }),
    },
  },
};

export const BOOKING_NATURE_LIST = Object.values(BOOKING_NATURES);

// Default values

export const BOOKING_DEFAULT_ARRIVAL = "16:00";
export const BOOKING_DEFAULT_DEPARTURE = "10:00";

// État

/** Devis */
export const BOOKING_STATE_VALUE_PREORDERED = "pre";
/** Option de réservation */
export const BOOKING_STATE_VALUE_PROMISED = "pro";
/** Réservation en cours */
export const BOOKING_STATE_VALUE_OPENED = "ope";
/** Réservation refusée */
export const BOOKING_STATE_VALUE_REFUSED = "ref";
/** Réservation payée */
export const BOOKING_STATE_VALUE_SOLDED = "sol";
/** Réservation abandonnée */
export const BOOKING_STATE_VALUE_ABORTED = "abt";
/** Réservation annulée */
export const BOOKING_STATE_VALUE_CANCELLED = "can";

/** @type {Record<import("../types/alfred/Booking").BookingState, { id: import("../types/alfred/Booking").BookingState, label: string, label2: string, consoleIcon: string, color: string }>} */
export const BOOKING_STATES = {
  [BOOKING_STATE_VALUE_PREORDERED]: {
    id: BOOKING_STATE_VALUE_PREORDERED,
    label: "devis",
    label2: "devis",
    consoleIcon: "clock-o",
    color: "#333333",
  },
  [BOOKING_STATE_VALUE_PROMISED]: {
    id: BOOKING_STATE_VALUE_PROMISED,
    label: "option de réservation",
    label2: "option",
    consoleIcon: "clock-o",
    color: "#333333",
  },
  [BOOKING_STATE_VALUE_OPENED]: {
    id: BOOKING_STATE_VALUE_OPENED,
    label: "réservation en cours",
    label2: "en cours",
    consoleIcon: "suitcase",
    color: "#23b7e5",
  },
  [BOOKING_STATE_VALUE_REFUSED]: {
    id: BOOKING_STATE_VALUE_REFUSED,
    label: "réservation refusée",
    label2: "refusée",
    consoleIcon: "times",
    color: "#f500cc",
  },
  [BOOKING_STATE_VALUE_SOLDED]: {
    id: BOOKING_STATE_VALUE_SOLDED,
    label: "réservation payée",
    label2: "soldée",
    consoleIcon: "check-circle-o",
    color: "#27c24c",
  },
  [BOOKING_STATE_VALUE_ABORTED]: {
    id: BOOKING_STATE_VALUE_ABORTED,
    label: "réservation abandonnée",
    label2: "abandonnée",
    consoleIcon: "trash",
    color: "#ca08f7",
  },
  [BOOKING_STATE_VALUE_CANCELLED]: {
    id: BOOKING_STATE_VALUE_CANCELLED,
    label: "réservation annulée",
    label2: "annulée",
    consoleIcon: "times",
    color: "#f500cc",
  },
};

export const BOOKING_STATE_LIST = Object.values(BOOKING_STATES);
export const BOOKING_STATES_VALUES = BOOKING_STATE_LIST.map(
  (state) => state.id,
);

// Source

/** Site web */
export const BOOKING_SOURCE_VALUE_WEB = "wb";
/** Distribution */
export const BOOKING_SOURCE_VALUE_EXT = "ex";
/** Agent */
export const BOOKING_SOURCE_VALUE_OWN = "ow";
/** Propriétaire */
export const BOOKING_SOURCE_VALUE_SR = "sr";
/** E-mail */
export const BOOKING_SOURCE_VALUE_MAIL = "ma";
/** Agence */
export const BOOKING_SOURCE_VALUE_AGENCY = "ag";
/** CE gitesdefrance.com */
export const BOOKING_SOURCE_VALUE_CE = "ce";
/** gitesdefrance.com */
export const BOOKING_SOURCE_VALUE_FNGDF = "fn";
/** Booking */
export const BOOKING_SOURCE_VALUE_BOOKING = "booking";
/** Expedia */
export const BOOKING_SOURCE_VALUE_EXPEDIA = "expedia";
/** CRS nationale */
export const BOOKING_SOURCE_VALUE_CRS_NATIONALE = "crs_nat";
/** Origine France */
export const BOOKING_SOURCE_VALUE_ORIGINE_FRANCE = "ori_fr";
/** FDT Province de Liège */
export const BOOKING_SOURCE_VALUE_FDT_PROVINCE_LIEGE = "fdt_pro_li";
/** Google */
export const BOOKING_SOURCE_VALUE_GOOGLE = "google";
/** Vaolo */
export const BOOKING_SOURCE_VALUE_VAOLO = "vaolo";
/** Vendée Tourisme */
export const BOOKING_SOURCE_VALUE_VENDEE_TOURISME = "ex_vendee_tourisme";
/** CRDT Auvergne */
export const BOOKING_SOURCE_VALUE_CRDT_AUVERGNE = "crdt_auvergne";
/** MDDT Haute-Loire */
export const BOOKING_SOURCE_VALUE_MDDT_HAUTE_LOIRE = "mddt_haute_loire";
/** Open System Original France */
export const BOOKING_SOURCE_VALUE_OPEN_SYSTEM_ORIGINAL_FRANCE =
  "open_sys_ori_fr";
/** City Break */
export const BOOKING_SOURCE_VALUE_CITY_BREAK = "city_break";
/** CRT Centre Val de Loire */
export const BOOKING_SOURCE_VALUE_CRT_CENTRE_VAL_DE_LOIRE = "crt_centre_val";
/** Pays de la Loire */
export const BOOKING_SOURCE_VALUE_PAYS_DE_LA_LOIRE = "pays_de_la_loire";
/** Rhône Tourisme */
export const BOOKING_SOURCE_VALUE_RHONE_TOURISME = "rhone_tourisme";
/** HDA Koedia */
export const BOOKING_SOURCE_VALUE_HDA_KOEDIA = "hda_koedia";

export const BOOKING_SOURCES = {
  [BOOKING_SOURCE_VALUE_WEB]: {
    id: BOOKING_SOURCE_VALUE_WEB,
    label: "web",
    metrics: {
      visible: true,
      color: "#DB9645",
    },
    delayCustomerPayment: false,
  },
  [BOOKING_SOURCE_VALUE_EXT]: {
    id: BOOKING_SOURCE_VALUE_EXT,
    label: "distribution",
    metrics: {
      visible: true,
      color: "#5C8ABB",
    },
    delayCustomerPayment: false,
  },
  [BOOKING_SOURCE_VALUE_OWN]: {
    id: BOOKING_SOURCE_VALUE_OWN,
    label: "agent",
    metrics: {
      visible: true,
      color: "#B97BCF",
    },
    delayCustomerPayment: false,
  },
  [BOOKING_SOURCE_VALUE_SR]: {
    id: BOOKING_SOURCE_VALUE_SR,
    label: "propriétaire",
    metrics: {
      visible: true,
      color: "#5CBB82",
    },
    delayCustomerPayment: false,
  },
  [BOOKING_SOURCE_VALUE_MAIL]: {
    id: BOOKING_SOURCE_VALUE_MAIL,
    label: "e-mail",
    metrics: {
      visible: true,
      color: "#81499A",
    },
    delayCustomerPayment: false,
  },
  [BOOKING_SOURCE_VALUE_AGENCY]: {
    id: BOOKING_SOURCE_VALUE_AGENCY,
    label: "agence",
    metrics: {
      visible: true,
      color: "#7A9726",
    },
    delayCustomerPayment: false,
  },
  [BOOKING_SOURCE_VALUE_CE]: {
    id: BOOKING_SOURCE_VALUE_CE,
    label: "CE gitesdefrance.com",
    metrics: {
      visible: true,
      color: "#808080",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_FNGDF]: {
    id: BOOKING_SOURCE_VALUE_FNGDF,
    label: "gitesdefrance.com",
    metrics: {
      visible: true,
      color: "#CF7C7B",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_BOOKING]: {
    id: BOOKING_SOURCE_VALUE_BOOKING,
    label: "Booking",
    metrics: {
      visible: true,
      color: "#003b95",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_EXPEDIA]: {
    id: BOOKING_SOURCE_VALUE_EXPEDIA,
    label: "Expedia",
    metrics: {
      visible: true,
      color: "#fddb32",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_CRS_NATIONALE]: {
    id: BOOKING_SOURCE_VALUE_CRS_NATIONALE,
    label: "CRS nationale",
    metrics: {
      visible: true,
      color: "#000091",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_ORIGINE_FRANCE]: {
    id: BOOKING_SOURCE_VALUE_ORIGINE_FRANCE,
    label: "Origine France",
    metrics: {
      visible: true,
      color: "#238cb2",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_FDT_PROVINCE_LIEGE]: {
    id: BOOKING_SOURCE_VALUE_FDT_PROVINCE_LIEGE,
    label: "FDT Province de Liège",
    metrics: {
      visible: true,
      color: "#00a651",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_GOOGLE]: {
    id: BOOKING_SOURCE_VALUE_GOOGLE,
    label: "Google",
    metrics: {
      visible: true,
      color: "#4285F4",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_VAOLO]: {
    id: BOOKING_SOURCE_VALUE_VAOLO,
    label: "Vaolo",
    metrics: {
      visible: true,
      color: "#00b181",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_VENDEE_TOURISME]: {
    id: BOOKING_SOURCE_VALUE_VENDEE_TOURISME,
    label: "Vendée Tourisme",
    metrics: {
      visible: true,
      color: "#37657b",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_CRDT_AUVERGNE]: {
    id: BOOKING_SOURCE_VALUE_CRDT_AUVERGNE,
    label: "CRDT Auvergne",
    metrics: {
      visible: true,
      color: "#00a651",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_MDDT_HAUTE_LOIRE]: {
    id: BOOKING_SOURCE_VALUE_MDDT_HAUTE_LOIRE,
    label: "MDDT Haute-Loire",
    metrics: {
      visible: true,
      color: "#2ea79b",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_OPEN_SYSTEM_ORIGINAL_FRANCE]: {
    id: BOOKING_SOURCE_VALUE_OPEN_SYSTEM_ORIGINAL_FRANCE,
    label: "Open System Original France",
    metrics: {
      visible: true,
      color: "#a36e76",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_CITY_BREAK]: {
    id: BOOKING_SOURCE_VALUE_CITY_BREAK,
    label: "City Break",
    metrics: {
      visible: true,
      color: "#814a97",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_CRT_CENTRE_VAL_DE_LOIRE]: {
    id: BOOKING_SOURCE_VALUE_CRT_CENTRE_VAL_DE_LOIRE,
    label: "CRT Centre Val de Loire",
    metrics: {
      visible: true,
      color: "#189cd9",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_PAYS_DE_LA_LOIRE]: {
    id: BOOKING_SOURCE_VALUE_PAYS_DE_LA_LOIRE,
    label: "Pays de la Loire",
    metrics: {
      visible: true,
      color: "#000fa0",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_RHONE_TOURISME]: {
    id: BOOKING_SOURCE_VALUE_RHONE_TOURISME,
    label: "Rhône Tourisme",
    metrics: {
      visible: true,
      color: "#000fa0",
    },
    delayCustomerPayment: true,
  },
  [BOOKING_SOURCE_VALUE_HDA_KOEDIA]: {
    id: BOOKING_SOURCE_VALUE_HDA_KOEDIA,
    label: "HDA Koedia",
    metrics: {
      visible: true,
      color: "#9ecadf",
    },
    delayCustomerPayment: true,
  },
};

export const BOOKING_SOURCE_LIST = Object.values(BOOKING_SOURCES);

// Canal de vente

export const BOOKING_WORKFLOW_VALUE_NATIVE = "native";
export const BOOKING_WORKFLOW_VALUE_OWNER = "owner";
export const BOOKING_WORKFLOW_VALUE_PARTNER = "partner";

export const BOOKING_WORKFLOWS = {
  [BOOKING_WORKFLOW_VALUE_NATIVE]: {
    id: BOOKING_WORKFLOW_VALUE_NATIVE,
    label: "contrat centrale",
    color: "#CF7C7B",
  },
  [BOOKING_WORKFLOW_VALUE_OWNER]: {
    id: BOOKING_WORKFLOW_VALUE_OWNER,
    label: "contrat location directe",
    color: "#81499A",
  },
  [BOOKING_WORKFLOW_VALUE_PARTNER]: {
    id: BOOKING_WORKFLOW_VALUE_PARTNER,
    label: "contrat partenaire",
    color: "#DB9645",
  },
};

export const BOOKING_WORKFLOW_LIST = Object.values(BOOKING_WORKFLOWS);

// Statuts OACK d'une réservation

export const BOOKING_OACK_STATE_VALUE_CONFIRMED = "con";
export const BOOKING_OACK_STATE_VALUE_REFUSED = "ref";
export const BOOKING_OACK_STATE_VALUE_UNKNOWN = "unk";

export const BOOKING_OACK_STATES = {
  [BOOKING_OACK_STATE_VALUE_CONFIRMED]: {
    id: BOOKING_OACK_STATE_VALUE_CONFIRMED,
    label: "confirmée",
    label2: "LSD validée",
    description: "Réservation en location sur demande validée",
    color: "#27c24c",
  },
  [BOOKING_OACK_STATE_VALUE_REFUSED]: {
    id: BOOKING_OACK_STATE_VALUE_REFUSED,
    label: "refusée",
    label2: "LSD refusée",
    description: "Réservation en location sur demande refusée",
    color: "#f05050",
  },
  [BOOKING_OACK_STATE_VALUE_UNKNOWN]: {
    id: BOOKING_OACK_STATE_VALUE_UNKNOWN,
    label: "inconnu",
    label2: "LSD en attente",
    description: "Réservation en location sur demande",
    color: "#50288c",
  },
};

export const BOOKING_OACK_STATE_LIST = Object.values(BOOKING_OACK_STATES);

// Raisons de refus d'une réservation OACK

/** Délai de réponse expiré */
export const BOOKING_OACK_REFUSAL_REASON_VALUE_EXPIRED = "expired";
/** Planning non disponible/pas à jour */
export const BOOKING_OACK_REFUSAL_REASON_VALUE_UNAVAILABLE = "unavailable";
/** Prix non conforme */
export const BOOKING_OACK_REFUSAL_REASON_VALUE_PRICE = "price";
/** Séjour non configuré */
export const BOOKING_OACK_REFUSAL_REASON_VALUE_SETUP = "setup";
/** Délai de réservation trop court */
export const BOOKING_OACK_REFUSAL_REASON_VALUE_DELAY = "delay";
/** Autre raison */
export const BOOKING_OACK_REFUSAL_REASON_VALUE_OTHER = "other";

export const BOOKING_OACK_REFUSAL_REASONS = {
  [BOOKING_OACK_REFUSAL_REASON_VALUE_EXPIRED]: {
    id: BOOKING_OACK_REFUSAL_REASON_VALUE_EXPIRED,
    label: "délai de réponse expiré",
    selectable: false,
  },
  [BOOKING_OACK_REFUSAL_REASON_VALUE_UNAVAILABLE]: {
    id: BOOKING_OACK_REFUSAL_REASON_VALUE_UNAVAILABLE,
    label: "planning non disponible/pas à jour",
    selectable: true,
  },
  [BOOKING_OACK_REFUSAL_REASON_VALUE_PRICE]: {
    id: BOOKING_OACK_REFUSAL_REASON_VALUE_PRICE,
    label: "prix non conforme",
    selectable: true,
  },
  [BOOKING_OACK_REFUSAL_REASON_VALUE_SETUP]: {
    id: BOOKING_OACK_REFUSAL_REASON_VALUE_SETUP,
    label: "séjour non configuré",
    selectable: true,
  },
  [BOOKING_OACK_REFUSAL_REASON_VALUE_DELAY]: {
    id: BOOKING_OACK_REFUSAL_REASON_VALUE_DELAY,
    label: "délai de réservation trop court",
    selectable: true,
  },
  [BOOKING_OACK_REFUSAL_REASON_VALUE_OTHER]: {
    id: BOOKING_OACK_REFUSAL_REASON_VALUE_OTHER,
    label: "autre raison",
    selectable: true,
  },
};

export const BOOKING_OACK_REFUSAL_REASON_LIST = Object.values(
  BOOKING_OACK_REFUSAL_REASONS,
);

// Sources de réponse d'une demande de réservation OACK

/** Réponse par un agent */
export const BOOKING_OACK_SOURCE_VALUE_ADMIN = "admin";
/** Réponse par un propriétaire */
export const BOOKING_OACK_SOURCE_VALUE_CUSTOMER = "customer";
/** Réponse automatique */
export const BOOKING_OACK_SOURCE_VALUE_SYSTEM = "system";

export const BOOKING_OACK_SOURCES = {
  [BOOKING_OACK_SOURCE_VALUE_ADMIN]: {
    id: BOOKING_OACK_SOURCE_VALUE_ADMIN,
    label: "conseiller séjour",
    selectable: true,
  },
  [BOOKING_OACK_SOURCE_VALUE_CUSTOMER]: {
    id: BOOKING_OACK_SOURCE_VALUE_CUSTOMER,
    label: "propriétaire",
    selectable: true,
  },
  [BOOKING_OACK_SOURCE_VALUE_SYSTEM]: {
    id: BOOKING_OACK_SOURCE_VALUE_SYSTEM,
    label: "automatique",
    selectable: false,
  },
};

export const BOOKING_OACK_SOURCE_LIST = Object.values(BOOKING_OACK_SOURCES);

// Source d'annulation

/** Client */
export const BOOKING_CANCELLATION_SOURCE_VALUE_CUSTOMER = "cust";
/** Propriétaire */
export const BOOKING_CANCELLATION_SOURCE_VALUE_OWNER = "own";
/** Agence */
export const BOOKING_CANCELLATION_SOURCE_VALUE_AGENCY = "agency";

export const BOOKING_CANCELLATION_SOURCES = {
  [BOOKING_CANCELLATION_SOURCE_VALUE_CUSTOMER]: {
    id: BOOKING_CANCELLATION_SOURCE_VALUE_CUSTOMER,
    label: "voyageur",
    ...("console" === process.env.APPLICATION_NAME && {
      consoleDescription:
        "Pénalité d’annulation supportée par le voyageur avec indemnisation possible du propriétaire.",
    }),
  },
  [BOOKING_CANCELLATION_SOURCE_VALUE_OWNER]: {
    id: BOOKING_CANCELLATION_SOURCE_VALUE_OWNER,
    label: "propriétaire",
    ...("console" === process.env.APPLICATION_NAME && {
      consoleDescription:
        "Indemnité d’annulation due au voyageur et facturée au propriétaire",
    }),
  },
  [BOOKING_CANCELLATION_SOURCE_VALUE_AGENCY]: {
    id: BOOKING_CANCELLATION_SOURCE_VALUE_AGENCY,
    label: "agence",
    ...("console" === process.env.APPLICATION_NAME && {
      consoleDescription:
        "Indemnité d’annulation due au voyageur et supportée par l’agence",
    }),
  },
};

export const BOOKING_CANCELLATION_SOURCE_LIST = Object.values(
  BOOKING_CANCELLATION_SOURCES,
);

// Raison d'annulation

/** Autre */
export const BOOKING_CANCELLATION_REASON_VALUE_OTHER = "other";
/** COVID */
export const BOOKING_CANCELLATION_REASON_VALUE_COVID = "covid";
/** Planning pas à jour */
export const BOOKING_CANCELLATION_REASON_VALUE_SCHEDULE_OUT_OF_DATE =
  "schedule_out_of_date";
/** Prix non conforme */
export const BOOKING_CANCELLATION_REASON_VALUE_WRONG_PRICE = "wrong_price";

export const BOOKING_CANCELLATION_REASONS = {
  [BOOKING_CANCELLATION_REASON_VALUE_OTHER]: {
    id: BOOKING_CANCELLATION_REASON_VALUE_OTHER,
    label: "autre",
    enabled: true,
  },
  [BOOKING_CANCELLATION_REASON_VALUE_COVID]: {
    id: BOOKING_CANCELLATION_REASON_VALUE_COVID,
    label: "COVID",
    enabled: false,
  },
  [BOOKING_CANCELLATION_REASON_VALUE_SCHEDULE_OUT_OF_DATE]: {
    id: BOOKING_CANCELLATION_REASON_VALUE_SCHEDULE_OUT_OF_DATE,
    label: "planning pas à jour",
    enabled: true,
  },
  [BOOKING_CANCELLATION_REASON_VALUE_WRONG_PRICE]: {
    id: BOOKING_CANCELLATION_REASON_VALUE_WRONG_PRICE,
    label: "prix non conforme",
    enabled: true,
  },
};

export const BOOKING_CANCELLATION_REASONS_BY_ALLOWED_CANCELLATION_SOURCE = {
  [BOOKING_CANCELLATION_SOURCE_VALUE_CUSTOMER]: [
    BOOKING_CANCELLATION_REASONS[BOOKING_CANCELLATION_REASON_VALUE_OTHER],
  ],
  [BOOKING_CANCELLATION_SOURCE_VALUE_OWNER]: [
    BOOKING_CANCELLATION_REASONS[BOOKING_CANCELLATION_REASON_VALUE_OTHER],
    BOOKING_CANCELLATION_REASONS[
      BOOKING_CANCELLATION_REASON_VALUE_SCHEDULE_OUT_OF_DATE
    ],
    BOOKING_CANCELLATION_REASONS[BOOKING_CANCELLATION_REASON_VALUE_WRONG_PRICE],
  ],
  [BOOKING_CANCELLATION_SOURCE_VALUE_AGENCY]: [
    BOOKING_CANCELLATION_REASONS[BOOKING_CANCELLATION_REASON_VALUE_OTHER],
  ],
};

export const BOOKING_CANCELLATION_REASON_LIST = Object.values(
  BOOKING_CANCELLATION_REASONS,
);

// Mode de remise

/** Remise supportée par tous */
export const BOOKING_DISCOUNT_MODE_VALUE_ALL = "al";
/** Remise supportée par le propriétaire */
export const BOOKING_DISCOUNT_MODE_VALUE_OWN = "ow";
/** Remise supportée par le label */
export const BOOKING_DISCOUNT_MODE_VALUE_SYSTEM = "sy";

export const BOOKING_DISCOUNT_MODES = {
  [BOOKING_DISCOUNT_MODE_VALUE_ALL]: {
    id: BOOKING_DISCOUNT_MODE_VALUE_ALL,
    label: "supportée par tous",
  },
  [BOOKING_DISCOUNT_MODE_VALUE_OWN]: {
    id: BOOKING_DISCOUNT_MODE_VALUE_OWN,
    label: "supportée par le propriétaire",
  },
  [BOOKING_DISCOUNT_MODE_VALUE_SYSTEM]: {
    id: BOOKING_DISCOUNT_MODE_VALUE_SYSTEM,
    label: "supportée par le label",
  },
};

export const BOOKING_DISCOUNT_MODE_LIST = Object.values(BOOKING_DISCOUNT_MODES);

// Raison d'exonération de taxe de séjour

/** Travailleur saisonnier */
export const BOOKING_TOURIST_TAX_EXEMPTION_REASON_VALUE_SEASONAL_WORKER =
  "seasonal_worker";
/** Hébergement d’urgence */
export const BOOKING_TOURIST_TAX_EXEMPTION_REASON_VALUE_EMERGENCY_ACCOMMODATION =
  "emergency_accommodation";
/** Autre */
export const BOOKING_TOURIST_TAX_EXEMPTION_REASON_VALUE_OTHER = "other";

export const BOOKING_TOURIST_TAX_EXEMPTION_REASONS = {
  [BOOKING_TOURIST_TAX_EXEMPTION_REASON_VALUE_SEASONAL_WORKER]: {
    id: BOOKING_TOURIST_TAX_EXEMPTION_REASON_VALUE_SEASONAL_WORKER,
    label: "travailleur saisonnier",
    selectable: true,
  },
  [BOOKING_TOURIST_TAX_EXEMPTION_REASON_VALUE_EMERGENCY_ACCOMMODATION]: {
    id: BOOKING_TOURIST_TAX_EXEMPTION_REASON_VALUE_EMERGENCY_ACCOMMODATION,
    label: "hébergement d’urgence",
    selectable: true,
  },
  [BOOKING_TOURIST_TAX_EXEMPTION_REASON_VALUE_OTHER]: {
    id: BOOKING_TOURIST_TAX_EXEMPTION_REASON_VALUE_OTHER,
    label: "autre",
    selectable: false,
  },
};

export const BOOKING_TOURIST_TAX_EXEMPTION_REASON_LIST = Object.values(
  BOOKING_TOURIST_TAX_EXEMPTION_REASONS,
);

// Type de prise de caution

/** Géré en interne via carte bancaire (paybox) */
export const BOOKING_GUARANTEE_TYPE_VALUE_INT_CCARD = "int_ccard";
/** Géré en interne via chèque */
export const BOOKING_GUARANTEE_TYPE_VALUE_INT_CHQ = "int_chq";
/** Géré en interne via virement */
export const BOOKING_GUARANTEE_TYPE_VALUE_INT_VIR = "int_vir";
/** Géré par le propriétaire en externe */
export const BOOKING_GUARANTEE_TYPE_VALUE_EXT_OWNER = "ext_owner";
/** Géré par Swikly (https://www.swikly.com/) */
export const BOOKING_GUARANTEE_TYPE_VALUE_SWIKLY = "swikly";
/** Géré par une caution annuelle sur le client */
export const BOOKING_GUARANTEE_TYPE_VALUE_CUSTOMER_GUARANTEE =
  "int_customer_guarantee";

export const BOOKING_GUARANTEE_TYPES = {
  [BOOKING_GUARANTEE_TYPE_VALUE_INT_CCARD]: {
    id: BOOKING_GUARANTEE_TYPE_VALUE_INT_CCARD,
    label: "prise d’empreinte bancaire",
  },
  [BOOKING_GUARANTEE_TYPE_VALUE_INT_CHQ]: {
    id: BOOKING_GUARANTEE_TYPE_VALUE_INT_CHQ,
    label: "chèque bancaire",
  },
  [BOOKING_GUARANTEE_TYPE_VALUE_INT_VIR]: {
    id: BOOKING_GUARANTEE_TYPE_VALUE_INT_VIR,
    label: "virement bancaire",
  },
  [BOOKING_GUARANTEE_TYPE_VALUE_EXT_OWNER]: {
    id: BOOKING_GUARANTEE_TYPE_VALUE_EXT_OWNER,
    label: "à sécuriser par le propriétaire",
  },
  [BOOKING_GUARANTEE_TYPE_VALUE_SWIKLY]: {
    id: BOOKING_GUARANTEE_TYPE_VALUE_SWIKLY,
    label: "sécurisation par le partenaire Swikly",
  },
  [BOOKING_GUARANTEE_TYPE_VALUE_CUSTOMER_GUARANTEE]: {
    id: BOOKING_GUARANTEE_TYPE_VALUE_CUSTOMER_GUARANTEE,
    label: "caution annuelle sur le client",
  },
};

export const BOOKING_GUARANTEE_TYPE_LIST = Object.values(
  BOOKING_GUARANTEE_TYPES,
);

// Anomalies

/** Balance des paiements du voyageur positive (Montant payé > Montant du séjour) */
export const BOOKING_ANOMALY_VALUE_CUSTOMER_OVERPAYMENT = "A-COP";
/** Balance des paiements du voyageur négative (Montant payé < Montant du séjour) */
export const BOOKING_ANOMALY_VALUE_CUSTOMER_UNDERPAYMENT = "A-CUP";
/** Balance des paiements du propriétaire positive (Montant payé > Montant des rétrocessions prévues) */
export const BOOKING_ANOMALY_VALUE_OWNER_OVERPAYMENT = "A-OOP";
/** Balance des paiements du propriétaire négative (Montant payé < Montant des rétrocessions prévues) */
export const BOOKING_ANOMALY_VALUE_OWNER_UNDERPAYMENT = "A-OUP";
/** Propriétaire concerné par le dossier sans coordonnées bancaires valides */
export const BOOKING_ANOMALY_VALUE_OWNER_BANK_INFOS_MISSING = "A-OBM";
/** Procédure d'annulation sans décompte voyageur */
export const BOOKING_ANOMALY_VALUE_CUSTOMER_CANCELLATION_MISSING = "A-CCM";
/** Procédure d'annulation sans décompte propriétaire */
export const BOOKING_ANOMALY_VALUE_OWNER_CANCELLATION_MISSING = "A-OCM";

export const BOOKING_ANOMALIES = {
  [BOOKING_ANOMALY_VALUE_CUSTOMER_OVERPAYMENT]: {
    id: BOOKING_ANOMALY_VALUE_CUSTOMER_OVERPAYMENT,
    label: "balance des paiements du voyageur positive",
  },
  [BOOKING_ANOMALY_VALUE_CUSTOMER_UNDERPAYMENT]: {
    id: BOOKING_ANOMALY_VALUE_CUSTOMER_UNDERPAYMENT,
    label: "balance des paiements du voyageur négative",
  },
  [BOOKING_ANOMALY_VALUE_OWNER_OVERPAYMENT]: {
    id: BOOKING_ANOMALY_VALUE_OWNER_OVERPAYMENT,
    label: "balance des paiements du propriétaire positive",
  },
  [BOOKING_ANOMALY_VALUE_OWNER_UNDERPAYMENT]: {
    id: BOOKING_ANOMALY_VALUE_OWNER_UNDERPAYMENT,
    label: "balance des paiements du propriétaire négative",
  },
  [BOOKING_ANOMALY_VALUE_OWNER_BANK_INFOS_MISSING]: {
    id: BOOKING_ANOMALY_VALUE_OWNER_BANK_INFOS_MISSING,
    label: "propriétaire concerné sans coordonnées bancaires valides",
  },
  [BOOKING_ANOMALY_VALUE_CUSTOMER_CANCELLATION_MISSING]: {
    id: BOOKING_ANOMALY_VALUE_CUSTOMER_CANCELLATION_MISSING,
    label: "procédure d'annulation sans décompte voyageur",
  },
  [BOOKING_ANOMALY_VALUE_OWNER_CANCELLATION_MISSING]: {
    id: BOOKING_ANOMALY_VALUE_OWNER_CANCELLATION_MISSING,
    label: "procédure d'annulation sans décompte propriétaire",
  },
};

export const BOOKING_ANOMALY_LIST = Object.values(BOOKING_ANOMALIES);
